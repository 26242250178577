<template>
    <div class="modal" v-if="modelValue" :class="{active : modelValue}" @click.self="closeModal">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Form <span>Send Form</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <Form @submit="handleSubmitForm" v-slot="{ errors }" ref="send-form-form">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Select Users</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.contacts }">
                                    <Field autocomplete="off" name="contacts" v-model="form.contacts" rules="required">
                                        <multiselect
                                            v-model="form.contacts"
                                            label="name"
                                            value-prop="id"
                                            mode="tags"
                                            :options="contacts"
                                            :searchable="true"
                                            :internal-search="false"
                                            @search-change="searchContact"
                                            placeholder="Select"
                                            trackBy="search_key"
                                        >
                                            <template v-slot:option="{ option }">
                                                <div class="multiselect-avatar">
                                                    <img class="character-option-icon" :src="option.photo">
                                                </div>
                                                <div class="multiselect-title-content">
                                                    <div class="multiselect-title">{{ option.name }}</div>
                                                    <div class="multiselect-sub-title">{{ option.email }}</div>
                                                </div>
                                            </template>
                                        </multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="contacts" class="text-danger" />
                            </div>
                        </div>
                        <label for="showPortal" class="switch_option capsule_btn border-0" v-show="form.contacts.length">
                            <h5 class="large">Show in Client Portal?</h5>
                            <input type="checkbox" id="showPortal" v-model="form.add_in_client_portal" :true-value="1" :fasle-value="0" hidden>
                            <div><span></span></div>
                        </label>
                    </Form>
                    <div class="cardItem_details" v-show="form.contacts.length">
                        <h3>Notification Method</h3>
                        <div class="edit_section">
                            <sending-method v-model="form.sending_method" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal_footer">
                <button type="button" :disabled="loader" class="btn cancel_btn" @click="closeModal">Cancel</button>
                <button type="button" :disabled="loader" class="btn save_btn" @click="handleSubmitForm"><i class="fa fa-spinner fa-spin" v-if="loader"></i> {{ loader ? 'Sending' : 'Send' }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'
    import { Field, Form, ErrorMessage } from 'vee-validate'

    const SendingMethod = defineAsyncComponent(() => import('@/components/SendingMethod'))
    
    import Multiselect from '@vueform/multiselect'

    export default {
        name: 'Send Form',

        data () {
            return {
                form: {
                    form_id: '',
                    sending_method: 3,
                    contacts: [],
                    add_in_client_portal: 1
                },
            }
        },

        props: {
            modelValue: Boolean,
            activeForm: Object,
        },

        emit: ['update:modelValue'],

        watch: {
            modelValue (value) {
                const vm = this;

                if (value) {
                    vm.form = {
                        form_id: [vm.activeForm.id],
                        sending_method: 3,
                        contacts: [],
                        add_in_client_portal: 1
                    },

                    vm.getAllContacts({});
                }
            },
        },

        components: {
            Multiselect,
            SendingMethod,
            Form,
            Field,
            ErrorMessage,
        },

        computed: mapState ({
            contacts: state => state.contactModule.allContacts,
            loader: state => state.formModule.formCreateLoader,
        }),

        methods: {
            ...mapActions({
                getAllContacts: 'contactModule/getAllContacts',
                sendForm: 'formModule/sendContactForm',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            searchContact (search) {
                const vm = this;

                vm.getAllContacts({ search });
            },

            handleSubmitForm () {
                const vm = this;

                const form = vm.$refs['send-form-form'];

                form.validate().then((result) => {
                    if (result.valid) {
                        vm.form.setFieldError = form.setFieldError;

                        vm.sendForm(vm.form).then((result) => {
                            vm.closeModal();
                        });
                    }
                });
            }
        }
    }
</script>
